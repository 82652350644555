body .TextInputstyles__TextInputRightElement-sc-xh55c1-2 span{
    font-size: 14px;
    margin-right: 10px;
}
body .budget-name span{
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
}

body .dentsu-comments .dentsu-avatar--border{
    border: 0;
}

body .scenario-name-text > div, body .scenario-name-text > div span {
display: flex;
align-items: center;
}

@media only screen and (max-width: 1300px) {
    .compare-scenarios-page .dentsu-page__content .dentsu-heading{
     font-size: 13px !important;
    }
    .compare-scenarios-page .dentsu-page__content .dentsu-format-currency{
     font-size: 16px;
    }
 
    .compare-scenarios-page .dentsu-page__content .dentsu-format-number{
     font-size: 16px;
    }
 
    .compare-scenarios-page .dentsu-page__content .dentsu-caption--secondary{
     font-size: 10px !important;
    }
 
    .compare-scenarios-page .dentsu-page__content .dentsu-caption--assistive{
     font-size: 10px !important;
    }
 
    .compare-scenarios-page .dentsu-page__content p[class*="dentsu-paragraph"]{
       font-size: 10px !important;
   }
 }